export const demoGlossaryList = [
  {
    letter: "A",
    list: [
      {
        name: "Apple",
        description: "Apple is a fruit.",
      },
      {
        name: "Algorithm",
        description:
          "An algorithm is a set of well-defined steps for performing a specific task or solving a problem.",
      },
    ],
  },
  {
    letter: "B",
    list: [
      {
        name: "Blockchain",
        description:
          "A blockchain is a decentralized and distributed digital ledger used for recording transactions across multiple computers.",
      },
      {
        name: "Big Data",
        description:
          "Big data refers to extremely large and complex data sets that require specialized tools and techniques for analysis.",
      },
    ],
  },
  {
    letter: "C",
    list: [
      {
        name: "Cloud Computing",
        description:
          "Cloud computing is the delivery of computing services, such as servers, storage, databases, networking, over the internet.",
      },
      {
        name: "Cybersecurity",
        description:
          "Cybersecurity is the practice of protecting computer systems, networks, and data from digital attacks and unauthorized access.",
      },
    ],
  },
  {
    letter: "D",
    list: [
      {
        name: "Data Science",
        description:
          "Data science is a multidisciplinary field that uses scientific methods, algorithms, processes, and systems to extract knowledge and insights from structured and unstructured data.",
      },
      {
        name: "Deep Learning",
        description:
          "Deep learning is a subset of machine learning that involves neural networks with multiple layers, used for complex pattern recognition tasks.",
      },
    ],
  },
  {
    letter: "E",
    list: [
      {
        name: "Encryption",
        description:
          "Encryption is the process of converting data into a code or cipher to secure it from unauthorized access or use.",
      },
      {
        name: "Artificial Intelligence (AI)",
        description:
          "Artificial intelligence is the development of computer systems that can perform tasks that typically require human intelligence, such as visual perception, speech recognition, and decision-making.",
      },
    ],
  },
  {
    letter: "F",
    list: [
      {
        name: "Fintech",
        description:
          "Fintech, short for financial technology, refers to the use of technology to provide financial services and improve financial processes.",
      },
      {
        name: "Front-end Development",
        description:
          "Front-end development involves creating the user interface and user experience of a website or application.",
      },
    ],
  },
  // Continue the list for other letters if needed...
];
